<template>
    <v-app id="inspire">
        <template>
            <HeaderComponent></HeaderComponent >
        </template>  
        <v-container class="pa-10">
            <v-card elevation="5" tile class="mx-auto" max-width="100%" nav>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left"> Website Names </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in $store.state.websiteList.siteListdata" :key="item.websitename" >
                            <td>
                                <a :href="item.website_url" class="d-flex list-item">
                                    <div><v-icon>mdi-earth</v-icon></div>
                                    <div>{{ item.website_name }}</div>
                                </a>
                            </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
            <v-overlay :value ="isLoading">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-container>
    </v-app>    
</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue'
export default {
    name: 'client-list',
    data () {
        return {
            load: true
        }
    },
    components:{
        HeaderComponent
    },
    async mounted () {
        await this.$store.dispatch('getAllSiteList')
        this.$store.commit('SET_LOADING', true)

        console.log(this.isLoading)
    },
    computed: {
        isLoading () {
            return this.$store.getters.getIsLoading
            
        }
    }
}
</script>
<style scoped>
a.d-flex.list-item{
    width: 100% !important;
    text-decoration: none !important;
}
a.d-flex.list-item div{
  color: #000;
  padding: 0 5px;
}
</style>