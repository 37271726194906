
import axios from 'axios'

import storeUrl from '@/store/index'

import auth from '@/auth/auth'

import {get, post} from '@/helper/helper'

const userToken = auth.token();

export default{
    state:{
        finalizing: [],
        checking: [],
        fixing: []
    },
    mutations: {
        set_finalizing(state:any, task_data:any){

            state.finalizing = task_data
        },
        set_checking(state:any, task_data:any){

            state.checking = task_data
        },
        set_fixing(state:any, task_data:any){

            state.fixing = task_data
        }
    },
    actions: {
        async finalizing({commit}:any){
            try {
      
                const tempUsertasks = await get("/projects/1/issues?labels=Finalizing&state=opened&per_page=100",{});

                // console.log(tempUsertasks.data)
                const userTaskData:any = []
      
                  for (const task of tempUsertasks.data) {
                    
                    const d = new Date(task.updated_at);
                    
                    const day = d.getDate();
                    const month = d.getMonth()+1;
                    const year = d.getFullYear();
                      userTaskData.push({
                          task_name: task.title,
                          labels: task.labels,
                          date: month + '-' + day + '-' + year,
                          web_url: task.web_url
                      })
                  }
                  console.log(userTaskData)
                  commit('set_finalizing', userTaskData)
            }
            catch (error) {
                alert(error)
                console.log(error)
            }
        },
        async checking({commit}:any){
            try {
      
                const tempUsertasks = await get("/projects/1/issues?labels=Final QA - For Checking&state=opened&per_page=100",{});

                // console.log(tempUsertasks.data)
                const userTaskData:any = []
      
                  for (const task of tempUsertasks.data) {
                    
                    const d = new Date(task.updated_at);
                    
                    const day = d.getDate();
                    const month = d.getMonth()+1;
                    const year = d.getFullYear();
                      userTaskData.push({
                          task_name: task.title,
                          labels: task.labels,
                          date: month + '-' + day + '-' + year,
                          web_url: task.web_url
                      })
                  }
                  console.log(userTaskData)
                  commit('set_checking', userTaskData)
            }
            catch (error) {
                alert(error)
                console.log(error)
            }
        },
        async fixing({commit}:any){
            try {
      
                const tempUsertasks = await get("/projects/1/issues?labels=Final QA - For Fixing&state=opened&per_page=100",{});

                // console.log(tempUsertasks.data)
                const userTaskData:any = []
      
                  for (const task of tempUsertasks.data) {
                    
                    const d = new Date(task.updated_at);
                    
                    const day = d.getDate();
                    const month = d.getMonth()+1;
                    const year = d.getFullYear();
                      userTaskData.push({
                          task_name: task.title,
                          labels: task.labels,
                          date: month + '-' + day + '-' + year,
                          web_url: task.web_url
                      })
                  }
                  console.log(userTaskData)
                  commit('set_fixing', userTaskData)
            }
            catch (error) {
                alert(error)
                console.log(error)
            }
        }
        
    } 
}