
import {get, post} from '@/helper/helper'

export default{
    state:{
        unassignedTasksList: []
    },
    mutations: {
        SET_UNASSIGNEDTASKS(state:any, unassigned_taskData:any){

            state.unassignedTasksList = unassigned_taskData
      
        }
    },
    actions: {
       async getUnassignedTask({commit}:any){

            try {
            
                const tempUsertasks = await get("/projects/1/issues?labels=Undergoing Revisions&state=opened&per_page=100", {});
                const userTaskData:any = []
                for (const task of tempUsertasks.data) {
                    if(task.assignees.length == 0 && task.assignee == null && task.labels.includes("stalled") != true){
                        const d = new Date(task.updated_at);
                        const day = d.getDate();
                        const month = d.getMonth()+1;
                        const year = d.getFullYear();
                        // console.log(task.updated_at)
                        userTaskData.push({
                            task: task.title,
                            labels: task.labels,
                            date: year + '-' + month + '-' + day,
                            web_url: task.web_url
                        })
                    }
                }
                commit('SET_UNASSIGNEDTASKS', userTaskData)
            }
            catch (error) {
                alert(error)
                console.log(error)
            }
       }
        
    } 
}