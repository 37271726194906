import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,{attrs:{"id":"inspire"}},[_c(VMain,{staticClass:"login-page",style:({backgroundImage:`url(${_vm.$store.state.app_url}/adonteam.jpg)`})},[_c('h1',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VLayout,{attrs:{"align-center":"","justify-center":""}},[_c(VFlex,{attrs:{"xs12":"","sm8":"","md4":""}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.onLogin()}}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("Login")])],1),_c(VCardText,[_c(VForm,[_c(VTextField,{attrs:{"id":"access-token","prepend-icon":"mdi-lock","name":"Access Token","label":"Access Token","type":"text"},model:{value:(_vm.accesstoken),callback:function ($$v) {_vm.accesstoken=$$v},expression:"accesstoken"}}),_c('span',{staticClass:"ml-8"},[_vm._v("Get your Token "),_c('a',{attrs:{"href":"https://gitlab.adondevelopment.com/-/profile/personal_access_tokens","target":"_blank"}},[_vm._v("here")])])],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","type":"submit"}},[_vm._v("Login")])],1)],1)],1)],1)],1)],1),_c(VOverlay,{attrs:{"value":_vm.overlay}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),(_vm.dialog == false)?_c(VSnackbar,{attrs:{"timeout":_vm.timeout,"top":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false))]}}],null,false,1424838584),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }