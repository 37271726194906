import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"space-around"}},[_c(VCol,[_c(VDialog,{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"view-more-btn"},[_c('a',_vm._g(_vm._b({attrs:{"href":"#"},on:{"click":_vm.prevD}},'a',attrs,false),on),[_c(VIcon,{attrs:{"dense":"","color":"#fff"}},[_vm._v("mdi-eye")]),_c('span',[_vm._v("View Tasks")])],1)])]}},{key:"default",fn:function(dialog){return [_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c('div',{staticClass:"user-avatar"},[_c('img',{attrs:{"src":_vm.avatarImage,"alt":"user-avatar"}})]),_c('div',{staticClass:"user-name"},[_c(VCardTitle,[_vm._v(_vm._s(_vm.name))]),_c(VCardSubtitle,{staticClass:"sub-header"},[_vm._v(_vm._s(_vm.jobTitle))])],1)]),_c(VCardText,[_c('div',{staticClass:"text-h2 pa-12 modal-body"},[_c('ul',_vm._l((_vm.listalltask),function(task){return _c('li',{key:task.id},[_c('div',[_c('a',{staticClass:"modal-link",attrs:{"href":task.web_url,"target":"_blank"}},[_c('h4',[_vm._v(_vm._s(task.taskName))]),_vm._l((task.labels),function(badge,index){return _c(VChip,{key:index,staticClass:"ma-1",style:({background: _vm.badgeColor(badge.label_name)}),attrs:{"dark":"","small":""}},[_vm._v(" "+_vm._s(badge.label_name))])})],2)])])}),0)])]),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Close")])],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }