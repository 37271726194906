
import axios from 'axios'

import storeUrl from '@/store/index'

import auth from '@/auth/auth'

import {get, post} from '@/helper/helper'

const userToken = auth.token();

export default{
    state:{
        stalledTasksList: []
    },
    mutations: {
        SET_STALLEDTASKS(state:any, unassigned_taskData:any){

            state.stalledTasksList = unassigned_taskData
        }
    },
    actions: {
        async getStalledTask({commit}:any){
            try {
      
                const tempUsertasks = await get("/projects/1/issues?labels=stalled&state=opened&per_page=100",{});

                // console.log(tempUsertasks.data)
                const userTaskData:any = []
      
                  for (const task of tempUsertasks.data) {
                    
                    if(task.assignees.length == 0 && task.assignee == null){
                    const d = new Date(task.updated_at);
                    
                    const day = d.getDate();
                    const month = d.getMonth()+1;
                    const year = d.getFullYear();
                      userTaskData.push({
                          task: task.title,
                          labels: task.labels,
                          date: month + '-' + day + '-' + year,
                          web_url: task.web_url
                      })
                    }
                  }
      
                  commit('SET_STALLEDTASKS', userTaskData)
            }
            catch (error) {
                alert(error)
                console.log(error)
            }
        }
        
    } 
}