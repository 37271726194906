<template>
    <v-data-table :headers="headers" :items="$store.state.stalledTasks.stalledTasksList" :items-per-page="10" class="elevation-3">
        <template v-slot:[`item.task`]="{ value }"> 
                {{value}}
        </template> 
        <template v-slot:[`item.web_url`]="{ value }"> 
            <a :href="value" target="_blank">
                <v-btn small color="primary"><v-icon  left dark class="pr-1">  mdi-open-in-new </v-icon> Link to task </v-btn>
            </a>    
        </template> 
    </v-data-table>
</template>

<script>

export default {
    data() {
        return {
            headers: [
            { text: 'Tasks', align: 'start', sortable: true, value: 'task' },
            { text: 'Links', value: 'web_url' },
            { text: 'Labels', value: 'labels' },
            { text: 'Last Date Updated', value: 'date' },
          ],
          itemName: [],
          itemUrl: []
        }
    },
    mounted() {
        this.$store.dispatch('getStalledTask')

        const task_arr = this.$store.state.stalledTasks.stalledTasksList;

        for(const key in task_arr){
            this.itemName.push(task_arr[key].task)
        }
        
        console.log(this.itemName)
        // this.itemUrl.push(this.$store.state.unassignedTasks.weburl)
    }
}
</script>
