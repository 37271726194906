
import {get} from '@/helper/helper'
// import { readFileSync, writeFileSync, promises as fsPromises } from 'fs';
// import { join } from 'path';
// import {get, post} from '@/helper/helper'

export default{
    state:{
        userInfo: [],
        is_Loading: true,
        has_react: {
            like: [],
            heart: []
        }
    },
    mutations: {
        SET_USERS(state:any, userData:any) {

            state.userInfo = userData
            
        },
        setLoading(state:any, payload:any){
            state.is_Loading = payload
        },
        SET_ADD_REACT(state:any, payload:any) {

                if(payload.name == 'like'){
                    state.has_react.like.push(payload)
                }
                else if (payload.name == 'heart'){
                    state.has_react.heart.push(payload)
                }

            
            state.is_Loading = false
            console.log(state.has_react)
        }
    },
    getters: {
        getIs_Loading(state:any) {
          return state.is_Loading
        },
        
      },
    actions: {
        mergedDataAllUsers({commit}:any) {
        
            get("/projects/1/users?per_page=100", {}).then(function(responses){
                const team:any = [];
                for(const user of responses.data){

                    get(`users/${user.id}`).then(function(userDetailResponse){
                        if(userDetailResponse.data.job_title == "Webteam Developer"){
                            
                            get(`/projects/1/issues?assignee_id=${user.id}`, {}).then(function(user_task_count){
                                let task_counter = 0;
                                const userTaskData:any = []
                                for(const item of user_task_count.data){
                            
                                    if(item.state == 'opened'){
                                        const labels_acro:any = [];
                                        for(const label_item of item.labels){
                                
                                            const initials = label_item.match(/\b(\w)/g).join('');

                                            labels_acro.push({
                                                label_initials: initials.toUpperCase(),
                                                label_name: label_item
                                            })
                                        }
                                        userTaskData.push({
                                            taskId: item.id,
                                            taskName: item.title,
                                            labels: labels_acro,
                                            web_url: item.web_url
                                        })
            
                                        task_counter++
                                    }
                                }
                                team.push({
                                    id: user.id,
                                    name: user.name,
                                    job_title: userDetailResponse.data.job_title,
                                    avatar_url: user.avatar_url,
                                    task_counter: task_counter,
                                    userTasks: userTaskData,
                                    
                                })
                            })
                        
                        }
                    })

                }

                commit('SET_USERS', team, { root: true } )
                // console.log(team);
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(function() {
                commit('setLoading', false);
            });
        
        },
        filterUserData({commit}:any) {
        
            get("/projects/1/users?per_page=100", {}).then(function(responses){
                const team:any = [];
                for(const user of responses.data){

                    get(`users/${user.id}`).then(function(userDetailResponse){
                        if(userDetailResponse.data.job_title == "Webteam Developer"){
                            
                            get(`/projects/1/issues?assignee_id=${user.id}`, {}).then(function(user_task_count){
                                let task_counter = 0;
                                const userTaskData:any = []
                                const userTaskData_all:any = []
                                // let task_label:any = null;
                                let discard:any = false;
                                for(const item of user_task_count.data){
                                    if(item.state == 'opened'){
                                        const labels_acro:any = [];
                                        
                                        for(const label_item of item.labels){
                                
                                            if(label_item == 'Sent For Review'){
                                                discard = true;
                                                
                                                const initials = label_item.match(/\b(\w)/g).join('');

                                                labels_acro.push({
                                                    label_initials: initials.toUpperCase(),
                                                    label_name: label_item
                                                })
                                            } 
                                            else 
                                            {
                                                const initials = label_item.match(/\b(\w)/g).join('');

                                                labels_acro.push({
                                                    label_initials: initials.toUpperCase(),
                                                    label_name: label_item
                                                })
                                                
                                            }
                                        }

                                        if(discard){
                                            userTaskData_all.push({
                                                taskId: item.id,
                                                taskName:item.title,
                                                labels: labels_acro,
                                                web_url: item.web_url
                                            })
                                            discard = false;
                                            task_counter++
                                            continue;
                                        }else{
                                            userTaskData.push({
                                                taskId: item.id,
                                                taskName:item.title,
                                                labels: labels_acro,
                                                web_url: item.web_url
                                            })

                                            userTaskData_all.push({
                                                taskId: item.id,
                                                taskName:item.title,
                                                labels: labels_acro,
                                                web_url: item.web_url
                                            })
                                            task_counter++
                                            discard = false;
                                        }
                                            
                                        
                                    }
                                }

                                team.push({
                                    id: user.id,
                                    name: user.name,
                                    job_title: userDetailResponse.data.job_title,
                                    avatar_url: user.avatar_url,
                                    task_counter: task_counter,
                                    userTasks: userTaskData,
                                    userTasks_all: userTaskData_all
                                })

                                // console.log(userTaskData_all);
                                
                            })
                        
                        }
                    })

                }

                commit('SET_USERS', team, { root: true } )
                console.log(team);
                // console.log(team[0].userTasks);
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(function() {
                commit('setLoading', false);
            });
        
        },

        addReact({commit}:any, payload:any){

            commit('SET_ADD_REACT', payload);

        }
    } 
}
