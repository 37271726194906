import Vue from 'vue'
import Vuex from 'vuex'
import allUsers from './modules/allUsers'
import allTasks from './modules/allTasks'
import unassignedTasks from './modules/unassignedTasks'
import finalTask from './modules/finalTask'
import stalledTasks from './modules/stalledTasks'
import websiteList from './modules/websiteList'
// import currentUser from './modules/currentUser'

Vue.use(Vuex)

export default new Vuex.Store({  
  state: {
    app_url: 'http://localhost:8080',
    baseUrl: 'https://gitlab.adondevelopment.com/api/v4',
    url: 'https://gitlab.adondevelopment.com',
  },
  getters: {
  },
  mutations: {
    
  },
  actions: {
    
  },
  modules: {
    allUsers,
    allTasks,
    unassignedTasks,
    stalledTasks,
    websiteList,
    finalTask
    // currentUser
  }
})

