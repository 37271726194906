
import {
    Component,
    Vue,
    Prop,
    // Watch
} from 'vue-property-decorator';

import {
    get,
    // post
} from '@/helper/helper';

import axios from 'axios';
import router from '@/router/index';
import Auth from '@/auth/auth';
import storeLogin from '@/store/modules/login'
// import storeUrl from '@/store/index'
// import allUsers from '@/store/modules/allUsers'
@Component
export default class Form extends Vue {
    @Prop() title!: string;

    drawer = false;
    accesstoken = '';
    snackbar = false;
    text = ''
    timeout = 2000;
    overlay = false;
    dialog = false;

    // @Watch('dialog')
    // onDialog(val:any) {
    //     if (!val) return
    //     // this.$store.commit('setDialog', true)
    //     // this.dialog = allUsers.state.dialog
    //     setTimeout(() => (this.dialog = false), 4000)

    //   }

    onLogin() {

        localStorage.removeItem('auth._user');
        localStorage.removeItem('auth._token');

        localStorage.setItem('auth._token', JSON.stringify(this.accesstoken))

        axios.defaults.headers.common['Authorization'] = `Bearer ${Auth.token()}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        get('user')
            .then((response) => {
                // console.log(response);
                storeLogin.state.auth.loggedIn = true;
                storeLogin.state.auth.user = response.data;
                localStorage.setItem('auth._user', JSON.stringify(response.data.name))
                this.$store.dispatch('getCurrentUser')
                this.$store.commit('SET_USER', response.data.name)
                localStorage.setItem('auth._user_data', JSON.stringify(response.data))
                this.snackbar = true
                this.overlay = true
                // this.text = 'Redirecting to dashboard, please wait ...'
                this.dialog = true
                
                setTimeout(() => {
                    router.push('list')
                }, 2000)

                console.log('access')
                
            })
            .catch((err) => {
                // console.log(err)
                // this.text = err.response.data.message
                if (err.response.status == 401) {
                    this.text = 'Unauthorized'
                    this.snackbar = true
                    localStorage.removeItem('auth._token');
                }

                if (err.response.status == 403) {
                    this.text = 'All fields are required!'
                    this.snackbar = true
                    localStorage.removeItem('auth._token');
                }

                this.dialog = false;
            })

    }

}
