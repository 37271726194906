<template>
<v-app id="inspire">
     <template>
       <HeaderComponent></HeaderComponent >
    </template>  
    <!-- <v-main class="main-body"> -->
        <v-container class="pa-10">
            <template>
                <v-row class="filter-cont">
                    <div class="text-right">
                        <v-menu v-model="filterMenu" :close-on-content-click="false" :nudge-width="200" offset-x>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-2" fab small color="#fff" v-bind="attrs" v-on="on">
                                    <v-icon> mdi-cog </v-icon>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-list>
                                <v-list-item>
                                    <v-list-item-title>Filter</v-list-item-title>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item>
                                    <v-list-item-action>
                                        <v-switch v-model="filterTasks" color="purple" @change="filter()"></v-switch>
                                    </v-list-item-action>
                                    <v-list-item-title>Sent For Review</v-list-item-title>
                                </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </div>

                </v-row>
                <v-row>
                    <v-overlay :value ="is_Loading">
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
                    <v-col v-for="user in this.$store.state.allUsers.userInfo" :key="user.id" cols="3" class="card-container">
                        <div class="badge-container">
                            <span v-if="user.task_counter != 0">{{ user.task_counter }}</span>
                        </div>
                        <v-card class="card-content" elevation="7">
                            <div class="card-header">
                                <div class="user-name">
                                    <v-card-title>{{user.name}}</v-card-title>
                                    <v-card-subtitle>{{ user.job_title }}</v-card-subtitle>
                                </div>
                                <div class="d-flex img-setting-cont">
                                    <div class="user-avatar">
                                        <img v-bind:src="user.avatar_url" alt="user-avatar">
                                    </div>
                                    <!-- <div>
                                        <v-menu bottom right :close-on-content-click="false" :nudge-width="200">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span elevation="0" small color="#fff" v-bind="attrs" v-on="on">
                                                    <v-icon> mdi-dots-vertical </v-icon>
                                                </span>
                                            </template>

                                            <v-card>
                                                <v-list>
                                                <v-list-item>
                                                    <v-list-item-title>Filter</v-list-item-title>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                                <v-list-item>
                                                    <v-list-item-action>
                                                        <v-switch v-model="filterTasks" color="purple" @change="userFilter(user.userTasks)"></v-switch>
                                                    </v-list-item-action>
                                                    <v-list-item-title>Sent For Review</v-list-item-title>
                                                </v-list-item>
                                                </v-list>
                                            </v-card>
                                        </v-menu>
                                    </div> -->
                                </div>
                            </div>
                            <div class="card-body">
                                <v-card-subtitle class="sub-header ">Assigned Tasks</v-card-subtitle>
                                <div class="task-list">

                                    <ul>
                                        <li v-for="(task2, index) in user.userTasks" :key="task2.id">
                                            <template v-if="index < 2 ">
                                                    <div class="task-title">

                                                    <v-card-text>
                                                        <a :href="task2.web_url" target="_blank" class="modal-link">
                                                            <p>{{ task2.taskName }}</p>
                                                        </a>
                                                    </v-card-text>

                                                    <div class="task-labels">
                                                        <v-tooltip bottom v-for="label in task2.labels" :key="label.label_name">
                                                            <template v-slot:activator="{ on, attrs }" >
                                                                <div v-bind="attrs" v-on="on" fab small dark>
                                                                    <v-chip class="ma-1" dark x-small :color="badgeColor(label.label_name)">{{ label.label_initials }}</v-chip>
                                                                </div>
                                                            </template>
                                                            <span class="white--text">{{ label.label_name }}</span>
                                                        </v-tooltip>
                                                    </div>
                                                </div>
                                                <v-divider></v-divider>
                                            </template>
                                        </li>
                                    </ul>

                                </div>
                                
                                <Modal v-if="filterTasks == true" :name="user.name" :avatarImage="user.avatar_url" :jobTitle="user.job_title" :listalltask="user.userTasks_all" />
                                <Modal v-if="filterTasks == false" :name="user.name" :avatarImage="user.avatar_url" :jobTitle="user.job_title" :listalltask="user.userTasks" />
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
                
                <div class="text-center">
                    <v-bottom-sheet hide-overlay inset>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn elevation="2" fab large color="#283D78" dark class="table-btn" v-bind="attrs" v-on="on">
                            <v-icon>mdi-table</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <!-- <v-subheader>Open in</v-subheader> -->
                        <v-list-item class="pa-5">
                            <v-row class="d-block">     
                                <v-tabs align-with- background-color="#283d78" v-model="currentItem" dark>
                                    <v-tab>Unassigned Tasks</v-tab>
                                    <v-tab>Stalled Tasks</v-tab>
                                    <v-tab>Finalizing Tasks</v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="currentItem">
                                    <v-tab-item class="pa-5">
                                        <UnassignedTasksDatatable  />
                                    </v-tab-item>
                                    <v-tab-item class="pa-5">
                                        <StalledTasksDatatable />
                                    </v-tab-item>
                                    <v-tab-item class="pa-5">
                                        <FinalizingTask />
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-row>
                        </v-list-item>
                    </v-list>
                    </v-bottom-sheet>
                </div>
            </template>
        </v-container>
    <!-- </v-main> -->
</v-app>
</template>

<script>
// import axios from 'axios'
// import CountTasks from '../components/TaskCount.vue'
import Modal from '../components/TaskModal.vue'
import UnassignedTasksDatatable from '../components/DataTables/UnassignedTask.vue'
import StalledTasksDatatable from '../components/DataTables/StalledTasks.vue'
import FinalizingTask from '../components/DataTables/FinalizingTask.vue'
import HeaderComponent from '../components/HeaderComponent.vue'



export default {
    name: 'listPage',
    components: {
        // CountTasks,
        Modal,
        UnassignedTasksDatatable,
        StalledTasksDatatable,
        FinalizingTask,
        HeaderComponent
    },
    data() {
        return {
            drawer: null,
            currentItem: 'tab-Web',
            right: null,
            dialog: true,
            load: true,
            filterMenu: false,
            filterTasks: JSON.parse(localStorage.getItem('filtered_task_status')) 
        }
    },  
    async mounted() {
        // await this.$store.dispatch('mergedDataAllUsers')
        if(this.filterTasks == null || this.filterTasks == 'null' || this.filterTasks == ''){
            localStorage.setItem('filtered_task_status', false)
            this.filterTasks = JSON.parse(localStorage.getItem('filtered_task_status')) 
            console.log(this.filterTasks)
        }else{
            localStorage.setItem('filtered_task_status', this.filterTasks)
            console.log(this.filterTasks)
        }
        

        if(this.filterTasks == true || this.filterTasks == 'true'){
            await this.$store.dispatch('filterUserData')
            this.$store.commit('setLoading', this.load)
            
            
        }else if(this.filterTasks == false || this.filterTasks == 'false'){
            await this.$store.dispatch('mergedDataAllUsers')//default get user data
            this.$store.commit('setLoading', this.load)
            
        }

        
        // console.log(this.$store.state.allUsers.userInfo)
        
    },
    computed: {
        is_Loading () {
            return this.$store.getters.getIs_Loading
        }
    },
    methods: {
        badgeColor(label) {
            if (label == 'Undergoing Revisions') {
                return '#4CAF50'
            } else if (label == 'go live notes') {
                return '#B71C1C'
            } else if (label == 'updates') {
                return '#311B92'
            } else if (label == 'Sent For Review') {
                return '#009688'
            } else if (label == 'Mocked Up') {
                return '#8BC34A'
            } else if (label == 'seo') {
                return '#7C4DFF'
            } else if (label == 'Web Design') {
                return '#FF4081'
            } else if (label == 'Initial Development - Sent to PM') {
                return '#7CB342'
            } else if (label == 'Initial Development - For Fixing') {
                return '#7E57C2'
            } else if (label == 'Initial Development - For Checking') {
                return '#1976D2'
            } else if (label == 'payments') {
                return '#827717'
            } else if (label == 'new website') {
                return '#FFCC80'
            } else if (label == 'seo meta and schema done') {
                return '#4FC3F7'
            } else if (label == 'post-live task') {
                return '#CDDC39'
            }
            
        },
        filter(){
            localStorage.setItem('filtered_task_status', this.filterTasks)

            if(this.filterTasks == true){
                this.$store.dispatch('filterUserData')
                
            }else if(this.filterTasks == false){
                this.$store.dispatch('mergedDataAllUsers')//default get user data
                
            }
            console.log('filter: ' + localStorage.getItem('filtered_task_status')); 
        }
        
    }
}
</script>

<style scoped>
@import '../assets/css/style.css';

/* .v-btn:not(.v-btn--round).v-size--large {
    height: 30px !important;
} */

</style>

