import { render, staticRenderFns } from "./FormComponent.vue?vue&type=template&id=df396b10&scoped=true&"
import script from "./FormComponent.vue?vue&type=script&lang=ts&"
export * from "./FormComponent.vue?vue&type=script&lang=ts&"
import style0 from "./FormComponent.vue?vue&type=style&index=0&id=df396b10&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df396b10",
  null
  
)

export default component.exports