
import { Component, Vue, Watch } from 'vue-property-decorator';
// import FormComponent from '@/components/Login/FormComponent.vue'
import Form from '@/components/Login/FormComponent.vue';
import storeLogin from '@/store/modules/login'
    @Component({
        components:{
            Form,
        }
    })


    export default class LoginView extends Vue {
       get loggedIn(){
        return storeLogin.getters.getIsLogin;
    }
    }
