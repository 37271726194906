
<template>
    <v-container>
        
        <v-app-bar app color="#283D78">
            
            <v-app-bar-nav-icon @click="drawer = !drawer" :collapse="drawer" color="white"></v-app-bar-nav-icon>

            <v-toolbar-title color="white">
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-img lazy-src="../assets/Rectangle.png" max-height="175" max-width="200" src="../assets/Rectangle.png"></v-img>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" app>
            <v-list-item>
                <v-list-item-content class="d-flex current-user">
                    <v-avatar size="62">
                        <img :lazy-src="current_user.avatar_url" max-height="175" max-width="200" :src="current_user.avatar_url">
                    </v-avatar>
                    <v-list-item-content>{{ current_user.name }}</v-list-item-content>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense nav>
                <v-list-item-group
                    v-model="selectedItem"
                    color="primary"
                >
                    <v-list-item v-for="item in home" :key="item.title" link>
                        <router-link :to="item.to" class="d-flex menu-item">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </router-link>    
                    </v-list-item>

                    <v-list-item v-for="item in about" :key="item.title" link>
                        <router-link :to="item.to" class="d-flex menu-item">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </router-link>    
                    </v-list-item>

                    <v-list-item v-for="item in client_list" :key="item.title" link> 
                        <router-link :to="item.to" class="d-flex menu-item">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </router-link>    
                    </v-list-item>

                    <v-list-item link  class="d-flex menu-item" @click="dialogl = true">
                        <v-list-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-icon>
                        <v-list-content>
                            <v-list-item-title class="ml-8">LogOut</v-list-item-title>
                        </v-list-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <v-row justify="center">
                <v-dialog
                v-model="dialogl"
                persistent
                max-width="290"
                >
                
                <v-card>
                    <v-card-title class="logout-text">
                    Are you sure you want to logout?
                    </v-card-title>
                    <!-- <v-card-text>Are you sure you want to logout?</v-card-text> -->
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialogl = false"
                    >
                        No
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="logOut"
                    >
                        Yes
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </v-navigation-drawer>
    </v-container>
</template>



<script>
import store from '@/store/modules/login'
import router from '@/router/index'

export default{

    data() {
        return {
            dialogl: false,
            drawer: null,
            currentItem: 'tab-Web',
            right: null,
            dialog: true,
            selectedItem: 0,
            current_user: JSON.parse(window.localStorage.getItem('auth._user_data')),
            home : [{
                title: 'Task List',
                icon: 'mdi-checkbox-marked-circle-plus-outline',
                to: '/list'
            }],
            about : [{
                title: 'About',
                icon: 'mdi-help-box',
                to: '/about'
            }],
            client_list : [{
                title: 'Website List',
                icon: 'mdi-format-list-text',
                to: '/client-list'
            }],
        }
    },
    methods : {
        logOut() {
            localStorage.removeItem('auth._token');
            localStorage.removeItem('auth._user');
            (async () => {
                await store.dispatch('logout');
                
                router.push({
                    name: 'login'
                });
                console.log('access')
            })();
        }
    },
    // async mounted() {
    //     await this.$store.dispatch('mergedDataAllUsers')
    // }
}
</script>
<style scoped>
.logout--class {
    text-decoration: none;
    width: 100%;
    display: block;
}
.v-card__title.logout-text {
    padding: 14px !important;
}
.v-list-item__content.d-flex > .v-avatar {
    flex: 0 0 30%;
}
.v-list-item__content.d-flex > .v-list-item__content {
    flex: 0 0 70%;
}
.current-user .v-list-item__content {
    justify-content: center;
}
.v-list-item__content.d-flex.current-user {
    padding: 17px 0px;
}
</style>

