

// import {get, post} from '@/helper/helper'

export default{
    state:{
        userTasks: []
    },
    mutations: {
        SET_TASKS(state:any, taskData:any) {

            state.userTasks = taskData
            
        }
    },
    actions: {
    //   async  getAllTasks({commit}:any){
           

        // const issuesResponse = await get("/projects/1/issues?", {})
        // console.log(issuesResponse)
        // for(const task of tempUsertasks.data){

        // }

            //  get("/projects/1/issues", {}).then((...responses) => {
      
            //       const tempUsertasks = responses[0].data
            //       const userTaskData:any = []
      
            //       for (const key in tempUsertasks) {
            //         if(tempUsertasks[key].assignees.length != 0 ){
            //           userTaskData.push({
            //             name: tempUsertasks[key].assignee.name,
            //             taskAssigned: {
            //               taskId: tempUsertasks[key].id,
            //               taskName: tempUsertasks[key].title,
            //               labels: tempUsertasks[key].labels,
            //               web_url: tempUsertasks[key].web_url
            //             }
            //           })
            //         }
            //       }
      
            //       // console.log(userTaskData);
      
            //       let groups:any =[]
            //       const grouped = userTaskData.reduce(function (obj:any, task:any) {
            //         obj[task.name] = obj[task.name] || [];
            //         obj[task.name].push(task.taskAssigned);
            //         return obj;
            //       }, {});
                    
            //       groups = Object.keys(grouped).map(function (key) {
            //         return {name: key, taskAssigned: grouped[key]};
            //       });
                  
            //       commit('SET_TASKS', groups)
            //   })
            
        // }
    } 
}