import Vue from 'vue'
import VueRouter from 'vue-router'
// import VueRouter, { RouteConfig } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import LoginView from '../views/LoginView.vue'
import ListView from '../views/ListView.vue'
import ClientList from '../views/ClientList.vue'
import store from '@/store/modules/login'




Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:[
    {
      path: '/',
      name: 'home',
      component: LoginView,
      meta:{
        isAuth: true
      }
    },
    {
      path: '/about',
      name: 'about',
      component: AboutView,
      meta:{
        requiredAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta:{
        isAuth: true
      }
    },
    {
      path: '/list',
      name: 'list',
      component: ListView,
      meta:{
        requiredAuth: true
      }
    },
    {
      path: '/client-list',
      name: 'client-list',
      component: ClientList,
      meta:{
        requiredAuth: true
      }
    },
  ]
})

router.beforeEach(async(to, from, next) => {

  if(to.matched.some(record => record.meta.isAuth)){
      await store.dispatch('validateToken')
    
      if(store.getters.getTokenStatus){
        
        next({
          path: '/list'
        })
      }else{
        next();
      }
  }else if(to.matched.some(record => record.meta.requiredAuth)){

    await store.dispatch('validateToken')

    if(!store.getters.getTokenStatus){
        
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    }else{
      next();
    }
  }else{
    next();
  }
})

export default router
