import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"inspire"}},[[_c('HeaderComponent')],_c(VContainer,{staticClass:"pa-10"},[_c(VCard,{staticClass:"mx-auto",attrs:{"elevation":"5","tile":"","max-width":"100%","nav":""}},[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Website Names ")])])]),_c('tbody',_vm._l((_vm.$store.state.websiteList.siteListdata),function(item){return _c('tr',{key:item.websitename},[_c('td',[_c('a',{staticClass:"d-flex list-item",attrs:{"href":item.website_url}},[_c('div',[_c(VIcon,[_vm._v("mdi-earth")])],1),_c('div',[_vm._v(_vm._s(item.website_name))])])])])}),0)]},proxy:true}])})],1),_c(VOverlay,{attrs:{"value":_vm.isLoading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }