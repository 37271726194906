<template>
    <v-row>
        <v-col>
            <v-card>
                <v-subheader style="color:$store.state.finalTask.finalizing.color">Finalizing</v-subheader>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item-group>
                        <v-list-item v-for="(task, index) in $store.state.finalTask.finalizing" :key="index">
                            <v-subheader>
                                <a :href="task.web_url">
                                    {{task.task_name}}
                                </a>
                            </v-subheader>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-col>
        <v-col>
            <v-card>
                <v-subheader>Final QA - For Checking</v-subheader>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item-group>
                        <v-list-item v-for="(task, index) in $store.state.finalTask.checking" :key="index">
                            <v-subheader>
                                <a :href="task.web_url">
                                    {{task.task_name}}
                                </a>
                            </v-subheader>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-col>
        <v-col>
            <v-card>
                <v-subheader>Final QA - For Fixing</v-subheader>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item-group>
                        <v-list-item v-for="(task, index) in $store.state.finalTask.fixing" :key="index">
                            <v-subheader>
                                <a :href="task.web_url">
                                    {{task.task_name}}
                                </a>
                            </v-subheader>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    data() {
        return {
            
        }
    },
    async mounted() {
        await this.$store.dispatch('finalizing')
        await this.$store.dispatch('checking')
        await this.$store.dispatch('fixing')
    }
}
</script>
<style scoped>
.row {
    display: flex;
}
.row .col{
    flex: 0 0 31%;
}
.col .v-card {
    height: 100%;
    padding: 10px;
}
.col .v-card a {
    text-decoration: none;
}
.col .v-card > .v-subheader{
    font-weight: 500;
}
.col .v-card .v-list .v-subheader a{
    font-weight: 500;
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;
    width: 250px;
}
</style>