import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,[_c(VCard,[_c(VSubheader,{staticStyle:{"color":"$store.state.finalTask.finalizing.color"}},[_vm._v("Finalizing")]),_c(VDivider),_c(VList,[_c(VListItemGroup,_vm._l((_vm.$store.state.finalTask.finalizing),function(task,index){return _c(VListItem,{key:index},[_c(VSubheader,[_c('a',{attrs:{"href":task.web_url}},[_vm._v(" "+_vm._s(task.task_name)+" ")])])],1)}),1)],1)],1)],1),_c(VCol,[_c(VCard,[_c(VSubheader,[_vm._v("Final QA - For Checking")]),_c(VDivider),_c(VList,[_c(VListItemGroup,_vm._l((_vm.$store.state.finalTask.checking),function(task,index){return _c(VListItem,{key:index},[_c(VSubheader,[_c('a',{attrs:{"href":task.web_url}},[_vm._v(" "+_vm._s(task.task_name)+" ")])])],1)}),1)],1)],1)],1),_c(VCol,[_c(VCard,[_c(VSubheader,[_vm._v("Final QA - For Fixing")]),_c(VDivider),_c(VList,[_c(VListItemGroup,_vm._l((_vm.$store.state.finalTask.fixing),function(task,index){return _c(VListItem,{key:index},[_c(VSubheader,[_c('a',{attrs:{"href":task.web_url}},[_vm._v(" "+_vm._s(task.task_name)+" ")])])],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }