<template>
    <v-data-table :headers="headers" :items="$store.state.unassignedTasks.unassignedTasksList" :items-per-page="10" class="elevation-3">
        <template v-slot:[`item.task`]="{ value }"> 
                {{value}}
        </template> 
        <template v-slot:[`item.web_url`]="{ value }"> 
            <a :href="value" target="_blank">
                <v-btn small color="primary"><v-icon  left dark class="pr-1">  mdi-open-in-new </v-icon> Link to task </v-btn>
            </a>    
        </template> 
    </v-data-table>
</template>

<script > 



export default {

    data() {
        return {
            headers: [
            { text: 'Tasks', align: 'start', sortable: true, value: 'task' },
            { text: 'Links', value: 'web_url' },
            { text: 'Labels', value: 'labels' },
            { text: 'Last Date Updated', value: 'date' },
          ],
          itemName: [],
          itemUrl: []
        }
    },
    async mounted() {
        await  this.$store.dispatch('getUnassignedTask')
        const task_arr = this.$store.state.unassignedTasks.unassignedTasksList;
        for(const key in task_arr){
            this.itemName.push(task_arr[key].task)
            
        }
        // console.log(this.$store.state.unassignedTasks.unassignedTasksList)
    }
}
</script>
