<template>
  <v-app id="inspire">
     <template>
          <HeaderComponent></HeaderComponent >
      </template>  
    <v-container class="pa-10">
      <v-card elevation="5" tile class="mx-auto" max-width="100%" nav>
        <div class="about">
            <v-list>
              <v-list-item-group class="members-wrapper">
                <template>
                    <v-list-item v-for="user in $store.state.allUsers.userInfo" :key="user.name" class="d-block img-container">
                      
                        <div class="d-flex justify-center img-wrapper">
                          <div class="d-flex justify-center">
                              <!-- <v-img :src="item.avatar_url" max-height="450" max-width="450"></v-img> -->
                              <v-avatar size="115">
                                <img :src="user.avatar_url" alt="image">
                              </v-avatar>
                          </div>
                          <div>
                            <v-list-item-content>
                              <v-list-item-title v-html="user.name" class="about-title"></v-list-item-title>
                            </v-list-item-content>
                          </div>
                          <template>
                            <div v-if="$store.state.allUsers.has_react.like.length != 0 || $store.state.allUsers.has_react.heart.length != 0" class="d-flex">
                              <div v-if="$store.state.allUsers.has_react.like.length != 0" class="d-flex align-content-center pr-2" style="align-items:center">
                                <v-avatar size="15" color="gray darken-2" elevation="2">
                                  <v-icon small color="blue" class="justify-center">
                                    mdi-thumb-up-outline
                                  </v-icon>
                                </v-avatar>
                              </div>
                              <div v-if="$store.state.allUsers.has_react.heart.length != 0" class="d-flex align-content-center pr-2" style="align-items:center">
                                <v-avatar size="15" color="gray darken-2" elevation="2">
                                  <v-icon small color="pink" class="justify-center">
                                    mdi-cards-heart-outline
                                  </v-icon>
                                </v-avatar>
                              </div>
                              <p class="text--disabled ma-0">{{ total_react }}</p>
                            </div>
                          </template>
                          <v-list>
                            <v-list-item-group class="d-flex">
                              <v-list-item v-for="item in react" :key="item.name" class="px-5 about" @click="reacted(item.name)">
                                <v-icon small :color="item.color" class="justify-center">
                                  {{item.icon}}
                                </v-icon>
                                
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </div>  
                    </v-list-item>
                </template>
              </v-list-item-group>
          </v-list>    
        </div>
      </v-card>
      <v-overlay :value ="is_Loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-container>  
  </v-app>  
</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue'
export default {
  name:'aboutView',
  components:{
    HeaderComponent
  },
  data(){
    return{
      react:[
        {
          name: 'like',
          icon: 'mdi-thumb-up',
          color: 'blue'
        },
        {
          name: 'heart',
          icon: 'mdi-cards-heart',
          color: 'pink'
        }
      ],
      load: true
    }
  },
  methods: {
    reacted(react){

      if (react == 'like'){
        this.$store.commit('SET_ADD_REACT', {  name: 'like'  } )
      }
      else if (react == 'heart'){
        this.$store.commit('SET_ADD_REACT', {  name: 'heart'  } )
      }
    }
  },
  async mounted () {
      await this.$store.dispatch('mergedDataAllUsers')
      
      // await this.$store.dispatch('getCurrentUser')

      this.$store.commit('setLoading', this.load)
    },
  computed: {
    is_Loading () {
        return this.$store.getters.getIs_Loading
    },
    total_react(){
      
      // const total = this.$store.state.allUsers.has_react.map(a => a.length).reduce((a,b) => a + b);

      return Object.values(this.$store.state.allUsers.has_react).flat().length

    }
  }
}
</script>
<style scoped>
.members-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-count: 4;
    justify-content:center;
}
.members-wrapper > div {
    flex: 0 0 25%
};
.v-avatar.v-list-item__avatar {
    margin: 0 auto !important;
}
.members-wrapper{
    height: 100%;
}
.members-wrapper > div {
    height: 35vh;
}
.img-wrapper {
    flex-wrap: wrap;
    align-items:
    center;
    align-content:
    center;
    height: 100%;
}
.img-wrapper > div {
    flex: 0 0 100%;
}
.v-list-item__title.about-title {
    text-align: center;
}
.about.px-5.v-list-item.v-list-item--link.theme--light {
    justify-content: center;
    background: #e5e5e530;
    border: 1px solid #e5e5e533;
    margin: 2px;
    min-height: 32px;
    border-radius: 5px;
}
p.text--disabled.ma-0 {
    font-size: 13px;
}
</style>

