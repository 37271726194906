<template>
    <v-row justify="space-around">
        <v-col>
            <v-dialog transition="dialog-bottom-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                    <div class="view-more-btn">
                        <a href="#" v-bind="attrs" v-on="on" @click="prevD">
                            <v-icon dense color="#fff">mdi-eye</v-icon>
                            <span>View Tasks</span>
                        </a>
                    </div>
                </template>
                <template v-slot:default="dialog" >
                <v-card>
                    <v-toolbar color="primary" dark>
                    <div class="user-avatar">
                        <img v-bind:src="avatarImage" alt="user-avatar">
                    </div>    
                    <div class="user-name">
                        <v-card-title>{{name}}</v-card-title>
                        <v-card-subtitle class="sub-header ">{{ jobTitle }}</v-card-subtitle>
                    </div>
                    </v-toolbar>
                    <v-card-text>
                        <div class="text-h2 pa-12 modal-body">
                            <ul>
                                <li v-for="task in listalltask" :key="task.id">
                                    <div> 
                                        <a :href="task.web_url" target="_blank" class="modal-link">
                                        <h4>{{ task.taskName }}</h4> 
                                        <v-chip v-for="(badge, index) in task.labels" :key="index" :style="{background: badgeColor(badge.label_name)}" class="ma-1" dark small> {{ badge.label_name }}</v-chip>
                                        </a>
                                    </div> 
                                </li>
                            </ul>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
                </template>
            </v-dialog>
        </v-col>
    </v-row>
</template>
<script>
export default{
    name: 'TaskModal',
    props:{
        name: String,
        avatarImage: String,
        jobTitle: String,
        listalltask: []
    },
    data() {
        return {
            listAllTasks: []
        }
    },
    methods: {
        prevD(e){
            e.preventDefault()
        },
        badgeColor(label){
            if (label == 'Undergoing Revisions') {
                return '#4CAF50'
            } else if (label == 'go live notes') {
                return '#B71C1C'
            } else if (label == 'updates') {
                return '#311B92'
            } else if (label == 'Sent For Review') {
                return '#009688'
            } else if (label == 'Mocked Up') {
                return '#8BC34A'
            } else if (label == 'seo') {
                return '#7C4DFF'
            } else if (label == 'Web Design') {
                return '#FF4081'
            } else if (label == 'Initial Development - Sent to PM') {
                return '#7CB342'
            } else if (label == 'Initial Development - For Fixing') {
                return '#7E57C2'
            } else if (label == 'Initial Development - For Checking') {
                return '#1976D2'
            } else if (label == 'payments') {
                return '#827717'
            } else if (label == 'new website') {
                return '#FFCC80'
            } else if (label == 'seo meta and schema done') {
                return '#4FC3F7'
            } else if (label == 'post-live task') {
                return '#CDDC39'
            }
            else {
                return '#1976d2'
            }
        }
    }
    
}
</script>
<style scoped>
.v-card__text {
    padding: 5px !important;
}
</style>