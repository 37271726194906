import axios from 'axios'

export default{
    state:{
        siteListdata : [],
        loading: true
    },
    mutations: {
        SET_SITELIST(state:any, list:any){
            state.siteListdata = list
        },
        SET_LOADING(state:any, payload:any){
            state.loading = payload
        }
    },
    actions: {
        async getAllSiteList({commit}:any){

                const list_arr:any = []
                
                axios.get('https://client-list.adondevelopment.com/site-list/').then(resp => {

                    for (const task in resp.data) {
                        
                       list_arr.push({website_name: resp.data[task].website_name, website_url: resp.data[task].website_url})
                        
                    }
                    
                })              
                .catch((err) => {
                    console.log(err)
                })
                .finally(function() {
                    commit('SET_LOADING', false)
                });

                commit('SET_SITELIST', list_arr)
        }
        
    },
    getters: {
        getIsLoading(state:any) {
            return state.loading
          }
    } 
}